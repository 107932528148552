<template>
<div class="pb-10 -mt-8 text-gray-800">

    

    <div class="block bg-lbe" v-if="tabs">
        <div class="border-b-4 border-lborange tracking-wide">
        <nav class="-mb-px flex space-x-5 overflow-x-auto overscroll-contain no-scrollbar" aria-label="Tabs">
            <a href="#" @click="changeTab(index)" v-for="(tab, index) in tabs" :key="tab.attributes.name.textContent" :class="[activeTab == index ? 'border-white text-gray-600 hover:text-gray-600 hover:border-white whitespace-nowrap py-4 px-1 border-b-2 font-medium text-md' : '', 'border-transparent text-white hover:text-gray-600 hover:border-white whitespace-nowrap py-4 px-1 border-b-2 font-medium text-md']">
                {{ tab.attributes.name.textContent }}
            </a>
        </nav>
        </div>
    </div>
<div class="" v-html="name"></div>

    <div class="block pt-4" v-if="tabs">
        <div v-for="(tab, index) in tabs" v-show="activeTab == index" :key="tab.attributes.name.textContent">
            <div v-html="tab.innerHTML" class="space-y-2"></div>
        </div>
    </div>

    <div class="block pt-4" v-if="content">
        <div v-html="content"></div>
    </div>
    

</div>
</template>

<script>
import apiClient from "@/services/API";

export default {
  name: 'show',
    data () {
      return {
            name: ``,
            tabs: [],
            activeTab: 0,
            content: ``,
      }
    },
    components: {
        
    },
    methods: {
      async fetchData() {
        try {
          const url = '/show_data' + this.$route.path
          const response = await apiClient.get(url)
          const results = response.data
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(results, "text/html");
          
          const name = xmlDoc.getElementsByTagName("name");
          const tab = xmlDoc.getElementsByTagName("tab");
          const content = xmlDoc.getElementsByTagName("content");

          if (name.length != 0) {
            this.name = name[0].innerHTML;
          }
          if (tab.length != 0) {
              this.tabs = tab;
          } else {
              this.tabs = null;
          }
          if (content.length != 0) {
            this.content = content[0].innerHTML;
          }
        } catch (error) {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            console.log("Server Error:", error)
          } else if (error.request) {
            // client never received a response, or request never left
            console.log("Network Error:", error)
          } else {
            console.log("Client Error:", error)
          }
        }
      },
      changeTab(id) {
          this.activeTab = id;
      }
    },
    mounted() {
      this.fetchData();
    }
}
</script>